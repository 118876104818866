const Emergency = () => {
  return (
    <>
      <section className="container emergency-page d-flex align-items-center justify-content-center">
        <div className="emergency-card">
          <h1 className="text-center text-decoration-underline">About me</h1>
          <h2>Name: Patrik Birula</h2>
          <h3>Date of birth: 25.11.1994</h3>
          <h3>Emergency contact: +420&nbsp;723&nbsp;237&nbsp;148</h3>
        </div>
      </section>
    </>
  )
}

export default Emergency;