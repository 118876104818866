const QrCode = () => {
  return (
    <>
      <section className="container qr-code-page d-flex flex-column">
        <div className="row my-auto">
          <section className="text-center col-6 payment-methods">
            <a href="/payments" className="btn btn-primary w-xl-50 mt-auto d-flex justify-content-center align-items-center p-3">
              <div className="payment-symbol">
                <img src="/images/money.svg" alt=""/>
              </div>
              <h2 className="px-3">Payment methods</h2>
            </a>
          </section>
          <section className="text-center col-6 emergency-informations">
            <a href="/emergency" className="btn btn-danger w-xl-50 d-flex justify-content-center align-items-center p-3">
              <div className="emergency-cross">
                <img src="/images/emergency.svg" alt=""/>
              </div>
              <h2 className="px-3">Emergency informations</h2>
            </a>
          </section>
        </div>
      </section>
    </>
  );
};

export default QrCode;
