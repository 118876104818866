import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

const Payments = () => {
  const [amount, setAmount] = useState("");  // Stav pro sledování zadané částky
  const [qrImage, setQrImage] = useState(null);  // Stav pro uložení URL obrázku QR kódu
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const qrRef = useRef();
  const shareButtonRef = useRef();

  const accountNumber = 'CZ4706000000000249721401';
  const baseQRData = `SPD*1.0*ACC:${accountNumber}*CC:CZK*MSG:Platba z webu`;
  const qrData = amount ? `${baseQRData}*AM:${amount}` : baseQRData;

  const [copySuccess, setCopySuccess] = useState(''); // Stav pro zprávu o úspěšném kopírování

  const copyToClipboard = () => {
      navigator.clipboard.writeText(accountNumber)
          .then(() => {
              setCopySuccess('Číslo účtu bylo zkopírováno do schránky!');
              setTimeout(() => setCopySuccess(''), 2000); // Zpráva zmizí po 2 sekundách
          })
          .catch((err) => {
              console.error('Failed to copy: ', err);
              setCopySuccess('Kopírování do schránky selhalo.');
          });
  };

  const handleAmountChange = (e) => {
      setAmount(e.target.value);
  };

  const generateQrImage = () => {
    setQrImage(null);  // Vymažte starý QR obrázek

    setTimeout(() => {
        const qrCanvas = qrRef.current.querySelector("canvas");
        const backgroundCanvas = document.createElement("canvas");
        const padding = 10;

        // Nastavení rozměrů pozadí
        backgroundCanvas.width = qrCanvas.width + padding * 2;
        backgroundCanvas.height = qrCanvas.height + padding * 2;
        const ctx = backgroundCanvas.getContext("2d");

        // Kreslení pozadí
        ctx.fillStyle = "white";  // Barva pozadí
        ctx.fillRect(0, 0, backgroundCanvas.width, backgroundCanvas.height);

        // Kreslení QR kódu do středu pozadí
        ctx.drawImage(qrCanvas, padding, padding);

        // Převod výsledného obrázku na URL
        const imageUrl = backgroundCanvas.toDataURL("image/png");
        setQrImage(imageUrl);

        // Zobrazí tlačítko po animaci vyjetí QR kódu (2 sekundy)
        setTimeout(() => {
            setIsButtonVisible(true);
        }, 2000);
    }, 0);
  };

  const shareQRCode = async () => {
      if (!qrImage) {
          alert("Nejdříve prosím vygenerujte QR kód.");
          return;
      }

      // Převeďte URL QR obrázku na soubor, který je možné sdílet
      const response = await fetch(qrImage);
      const blob = await response.blob();
      const file = new File([blob], "qr_code.png", { type: "image/png" });

      if (navigator.share && navigator.canShare({ files: [file] })) {
          try {
              await navigator.share({
                  files: [file],
                  title: "QR Platba",
                  text: "Použijte tento QR kód pro platbu."
              });
          } catch (error) {
              console.error("Sdílení selhalo:", error);
          }
      } else {
          alert("Sdílení není na tomto zařízení podporováno.");
      }
  };

  return (
      <section className='container text-center payments-page d-flex align-items-center justify-content-center'>
        <div className="elements-wrapper d-flex flex-column align-items-center w-100">
          <h1>Platba</h1>
          <label className='text-center'>
              <span className='d-block'>
                Částka (Kč):
              </span>
              <input 
                  type="number" 
                  value={amount} 
                  onChange={handleAmountChange} 
                  placeholder="Zadejte částku" 
              />
          </label>
          <button onClick={generateQrImage} className='btn btn-danger my-3'>Generovat QR</button>
          <div ref={qrRef} style={{ display: 'none' }}>
              <QRCodeCanvas value={qrData}/>
          </div>
          <div className={qrImage ? 'qr-code-wrapper my-3' : "d-none"}>
            <img src={qrImage} alt="QR Platba" className="qr-code" />
          </div>
          <div className={`flex-column align-items-center qr-btns ${isButtonVisible ? "d-flex" : "d-none"}`}>
            <button onClick={shareQRCode} ref={shareButtonRef} className='btn btn-primary my-3'>Sdílet QR do bankovní aplikace</button>
            <button onClick={copyToClipboard} className='btn btn-danger my-3'>Zkopírovat číslo účtu</button>

            {copySuccess && <p>{copySuccess}</p>}
          </div>
        </div>
      </section>
  );
}

export default Payments;