import { useState } from "react";

import "./welcome.scss";
import Contacts from "../../components/Contacts/Contacts";

const Welcome = () => {
  const [contactsVisible, setContactsVisible] = useState(false);

  const toggleContacts = () => {
    if (contactsVisible) {
      setContactsVisible(false);
    } else {
      setContactsVisible(true);
    }
  };

  return (
    <>
      <section className="text-center">
        <h1>Přeji hezký den!</h1>
        <h2>Jmenuji se Patrik Birula a jsem frontend vývojář.</h2>
        <h3>Aktuálně se mé stránky nachází v pomalém vývoji &#128512;</h3>

        <h2>
          Ale pokud mne potřebujete kontaktovat, tak zde jsou veškeré možnosti
        </h2>

        <button className="btn btn-primary" onClick={toggleContacts}>
          Kontakty
        </button>
      </section>
      {contactsVisible && (
        <Contacts toggleContacts={toggleContacts} isVisible={contactsVisible} />
      )}
    </>
  );
};

export default Welcome;
