import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import Navbar from "./components/Navbar/Navbar";
import Welcome from "./pages/Welcome/Welcome";
import QrCode from "./pages/qrCode/QrCode";
import Payments from "./pages/Payments/Payments";
import Emergency from "./pages/Emergency/Emergency";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Welcome />} />
          <Route path="/qr-code" element={<QrCode />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/emergency" element={<Emergency />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;